import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const totalImages = 99;
  const [shuffledImages, setShuffledImages] = useState([]);
  const [isShuffling, setIsShuffling] = useState(false);
  const [buttonColor, setButtonColor] = useState('#4CAF50');
  const [hoveredImgId, setHoveredImgId] = useState(null);
  const [expandedImgId, setExpandedImgId] = useState(null);

  const shuffleArray = (array) => {
    let shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const handleShuffleClick = () => {
    setIsShuffling(true);
    setButtonColor(getRandomColor());
    setTimeout(() => {
      setShuffledImages((prevImages) => shuffleArray(prevImages));
      setIsShuffling(false);
    }, 500);
  };

  const handleImageClick = (i) => {
    setExpandedImgId(i);
  };

  const handleCloseClick = () => {
    setExpandedImgId(null);
  };

  useEffect(() => {
    let images = [];
    for (let i = 1; i <= totalImages; i++) {
      images.push(i);
    }
    setShuffledImages(shuffleArray(images));
  }, []);

  return (
    <div className="App">
      
      <button 
        className="shuffle-button" 
        onClick={handleShuffleClick} 
        style={{ backgroundColor: buttonColor }} 
      >
        Shuffle
      </button>
      <div className={`grid-container ${isShuffling ? 'fade-out' : 'fade-in'}`}>
        {shuffledImages.map(i => (
          <div key={i} className="image-container" onMouseLeave={() => setHoveredImgId(null)}>
            <img 
              src={`/${i}.gif`} 
              alt={`Image ${i}`}
              onClick={() => handleImageClick(i)} 
              className={expandedImgId === i ? 'expanded' : ''}
            />
            {hoveredImgId === i && !expandedImgId && (
              <button 
                className="view-button" 
                onMouseEnter={() => setHoveredImgId(i)}
                onClick={() => handleImageClick(i)}
              >
                View Image
              </button>
            )}
            {expandedImgId === i && (
              <button className="close-button" onClick={handleCloseClick}>X</button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
